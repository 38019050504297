const IMAGES = {
  // LOGO
  logo: require("../img/logo.png"),
  hlogo: require("../img/hlogo.png"),
  banner1: require("../img/home/banner1.jpeg"),
  banner2: require("../img/home/banner2.jpeg"),
  banner3: require("../img/home/banner3.jpeg"),
  banner4: require("../img/home/banner4.jpeg"),
  banner5: require("../img/home/banner5.jpeg"),
  safe: require("../img/products/safe.png"),
  coin: require("./coin.png"),
  upi: require("./upi.png"),
  paytm: require("./paytm.png"),
  gpay: require("./gpay.png"),
  phonepe: require("./phonepe.png"),
};

export default IMAGES;
