import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import AdminLayout from "./components/AdminLayout";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import "./AdminUsers.css";
import "./AdminAddProduct.css";

const AdminEditProduct = () => {
  const params = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [yokcash, setYokcash] = useState(null);
  const [moogold, setMoogold] = useState(null);
  const [servers, setServers] = useState(null);
  const [list, setList] = useState(null);

  const [form, setForm] = useState({
    name: "",
    company: "",
    desc: "",
    descTwo: "",
    category: "",
    api: "",
    apiName: "",
    gameName: "",
    region: "",
    stock: "Yes",
    bannerImage: null,
    instructionImage: null,
    userIdImage: null,
    playerCheckBtn: "no",
    servers: [{}],
    seq: "",
    use: "",
    categoryId: "",
    fields: "",
    tagOne: "",
    tagTwo: "",
  });

  const [cost, setCost] = useState([
    {
      id: "",
      amount: "",
      pack: "",
      price: "",
      fakePrice: "",
      pimg: "",
      resPrice: "",
      buyingprice: "",
      cat: "",
      cimg: "",
    },
  ]);

  const handleAddCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index + 1, 0, {
      id: "",
      amount: "",
      pack: "",
      price: "",
      fakePrice: "",
      pimg: "",
      resPrice: "",
      buyingprice: "",
      cat: "",
      cimg: "",
    });
    setCost(updatedCost);
  };

  const handleRemoveCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index, 1);
    setCost(updatedCost);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setForm({ ...form, [name]: e.target.files });
    } else if (
      name === "bannerImage" ||
      name === "instructionImage" ||
      name === "userIdImage"
    ) {
      setForm({ ...form, [name]: e.target.files[0] });
    } else if (name === "category") {
      setForm({ ...form, category: value });
    } else if (name === "categoryId") {
      setForm({ ...form, categoryId: value });
    } else if (
      name.startsWith("id") ||
      name.startsWith("amount") ||
      name.startsWith("pack") ||
      name.startsWith("price") ||
      name.startsWith("fakePrice") ||
      name.startsWith("pimg") ||
      name.startsWith("resPrice") ||
      name.startsWith("buyingprice") ||
      name.startsWith("cat") ||
      name.startsWith("cimg")
    ) {
      const index = parseInt(name.split("-")[1]);
      const updatedCost = [...cost];
      const property = name.startsWith("amount")
        ? "amount"
        : name.startsWith("price")
        ? "price"
        : name.startsWith("pimg")
        ? "pimg"
        : name.startsWith("resPrice")
        ? "resPrice"
        : name.startsWith("buyingprice")
        ? "buyingprice"
        : name.startsWith("pack")
        ? "pack"
        : name.startsWith("fakePrice")
        ? "fakePrice"
        : name.startsWith("cat")
        ? "cat"
        : name.startsWith("cimg")
        ? "cimg"
        : "id";
      updatedCost[index][property] = value;
      setCost(updatedCost);
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleCategoryChange = (selectedList) => {
    setForm((prevForm) => ({
      ...prevForm,
      category: selectedList,
    }));
  };

  const handleUpdateProduct = async () => {
    const formData = new FormData();
    formData.append("id", form?._id);
    formData.append("name", form?.name);
    formData.append("company", form?.company);
    formData.append("desc", form?.desc);
    formData.append("descTwo", form?.descTwo);
    formData.append("api", form?.api);
    formData.append("apiName", form?.apiName);
    formData.append("gameName", form?.gameName);
    formData.append("region", form?.region);
    formData.append("stock", form?.stock);
    formData.append("playerCheckBtn", form?.playerCheckBtn);
    formData.append("seq", form?.seq);
    formData.append("use", form?.use);
    formData.append("categoryId", form?.categoryId);
    formData.append("fields", form?.fields);
    formData.append("tagOne", form?.tagOne);
    formData.append("tagTwo", form?.tagTwo);
    formData.append("image", selectedFile);
    formData.append("servers", JSON.stringify(form.servers));
    form?.category?.forEach((categoryItem, index) => {
      formData.append(`category[${index}]`, categoryItem);
    });
    cost.forEach((costItem, index) => {
      formData.append(`cost[${index}][id]`, costItem.id);
      formData.append(`cost[${index}][amount]`, costItem.amount);
      formData.append(`cost[${index}][pack]`, costItem.pack);
      formData.append(`cost[${index}][price]`, costItem.price);
      formData.append(`cost[${index}][fakePrice]`, costItem.fakePrice);
      formData.append(`cost[${index}][pimg]`, costItem.pimg);
      formData.append(`cost[${index}][resPrice]`, costItem.resPrice);
      formData.append(`cost[${index}][buyingprice]`, costItem.buyingprice);
      formData.append(`cost[${index}][cat]`, costItem.cat);
      formData.append(`cost[${index}][cimg]`, costItem.cimg);
    });

    if (form.bannerImage) {
      formData.append("bannerImage", form.bannerImage);
    }
    if (form.instructionImage) {
      formData.append("instructionImage", form.instructionImage);
    }
    if (form.userIdImage) {
      formData.append("userIdImage", form.userIdImage);
    }

    setLoading(true);

    try {
      const res = await axios.post("/api/product/update-product", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        navigate("/admin-products");
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product", {
        id: params.id,
      });
      if (res.data.success) {
        setForm(res.data.data);
        setCost(res.data.data.cost);
        setSelectedFile(res.data.data.image);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  const getMobileLegendGame = async () => {
    try {
      const res = await axios.post(
        "/api/product/get-mobile-legend",
        { region: form?.region },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.success) {
        setData(res.data.data.product);
      } else {
        message.error("Api Error, Try after sometime");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (form?.region !== "") {
      getMobileLegendGame();
    }
  }, [form?.region]);

  // MOOGOLD
  const fetchMoogoldList = async () => {
    try {
      const res = await axios.post(
        "/api/moogold/list-product",
        {
          categoryId: form?.categoryId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setList(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    if (form?.categoryId !== "") {
      fetchMoogoldList();
    }
    // eslint-disable-next-line
  }, [form?.categoryId]);

  const fetchMoogoldServices = async () => {
    try {
      const res = await axios.post("/api/moogold/moogold-product", {
        product_id: form?.gameName,
      });
      if (res.data.success) {
        setMoogold(res.data.data.Variation);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchMoogoldServers = async () => {
    try {
      const res = await axios.post("/api/moogold/moogold-servers", {
        product_id: form?.gameName,
      });
      if (res.data.success) {
        setServers(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    if (form?.apiName === "moogold" && form?.gameName !== "") {
      fetchMoogoldServices();
      fetchMoogoldServers();
    }
  }, [form?.gameName]);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Edit Product</h3>
        </div>
        <hr />
        <div className="add-product-container">
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="image"
              required
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <span className="text-dark">
            <small>Banner Image</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Banner Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="bannerImage"
              required
              ref={fileInputRef}
              onChange={handleChange}
            />
          </div>
          <span className="text-dark">
            <small>Instruction Image</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Instruction Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="instructionImage"
              required
              ref={fileInputRef}
              onChange={handleChange}
            />
          </div>
          <span className="text-dark">
            <small>User Id Image</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Instruction Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="userIdImage"
              required
              ref={fileInputRef}
              onChange={handleChange}
            />
          </div>
          <label htmlFor="" className="text-dark">
            <small>Product Name</small>
          </label>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="name"
              onChange={handleChange}
              value={form?.name}
              type="text"
              placeholder="Enter name"
            />
          </div>
          <label htmlFor="" className="text-dark">
            <small>Company Name</small>
          </label>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="company"
              onChange={handleChange}
              value={form?.company}
              type="text"
              placeholder="Enter company name"
            />
          </div>
          <label htmlFor="" className="text-dark">
            <small>Fields</small>
          </label>
          <div className="form-fields mb-3">
            <select
              onChange={handleChange}
              value={form?.fields}
              name="fields"
              className="w-100"
            >
              <option value="">Fields</option>
              <option value="1">1 (USER ID/PLAYER ID/Link)</option>
              <option value="2">2 (USERID ZONEID)</option>
              <option value="3">3 (USERID AND SELECT SERVER)</option>
            </select>
          </div>
          {(form?.fields === "1" ||
            form?.fields === "2" ||
            form?.fields === "3") && (
            <div className="form-fields mb-3">
              <input
                className="w-100"
                name="tagOne"
                onChange={handleChange}
                value={form?.tagOne}
                type="text"
                placeholder="Enter Tag One"
              />
            </div>
          )}
          {(form?.fields === "2" || form?.fields === "3") && (
            <div className="form-fields mb-3">
              <input
                className="w-100"
                name="tagTwo"
                onChange={handleChange}
                value={form?.tagTwo}
                type="text"
                placeholder="Enter Tag Two"
              />
            </div>
          )}
          <label htmlFor="" className="text-dark">
            <small>Add Description</small>
          </label>
          <div className="form-fields mb-3">
            <textarea
              style={{ border: "1px solid #000" }}
              name="desc"
              id=""
              cols="30"
              rows="3"
              placeholder="Description"
              className="form-control"
              onChange={handleChange}
              value={form?.desc}
            ></textarea>
          </div>
          <label htmlFor="" className="text-dark">
            <small>Category</small>
          </label>
          <div className="form-fields mb-3">
            <Multiselect
              id="category"
              className="multiselect-wrapper"
              isObject={false}
              onRemove={handleCategoryChange}
              onSelect={handleCategoryChange}
              selectedValues={form.category}
              options={[
                "Popular Games",
                "Instant Topup",
                "Mobile Legends",
                "Social Media",
                "Vouchers",
                "Live App",
                "OTTS",
                "Others",
              ]}
            />
          </div>
          www
          <label htmlFor="" className="text-dark">
            <small>API Based</small>
          </label>
          <div className="form-fields mb-3">
            <select
              onChange={handleChange}
              value={form?.api}
              name="api"
              className="w-100"
            >
              <option value="">API BASED?</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          {form?.api === "yes" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.apiName}
                name="apiName"
                className="w-100"
              >
                <option value="">Select API</option>
                <option value="smileOne">Smile One Api</option>
                <option value="moogold">Moogold</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "moogold" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.categoryId}
                name="categoryId"
                className="w-100"
              >
                <option value="">Select Moogold Category</option>
                <option value="50">Direct-top up products</option>
                <option value="51">Other Gift Cards</option>
                <option value="1391">Amazon Gift Cards</option>
                <option value="1444">Apple Music</option>
                <option value="755">Garena Shells</option>
                <option value="538">Google Play</option>
                <option value="2433">iTunes Gift Card</option>
                <option value="1223">League of Legends</option>
                <option value="874">Netflix</option>
                <option value="765">PSN</option>
                <option value="451">Razer Gold</option>
                <option value="1261">Riot Access Code</option>
                <option value="992">Spotify</option>
                <option value="993">Steam</option>
                <option value="2377">Apex Legends</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "moogold" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.gameName}
                name="gameName"
                className="w-100"
              >
                <option value="">Select Game</option>
                {list &&
                  list.map((item, index) => {
                    return <option value={item.ID}>{item?.post_title}</option>;
                  })}
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "smileOne" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.region}
                name="region"
                className="w-100"
              >
                <option value="">Select Region</option>
                <option value="brazil">Brazil</option>
                <option value="philippines">Philippines</option>
              </select>
            </div>
          )}
          <div className="form-fields mb-3">
            <label htmlFor="" className="text-dark">
              <small>Stock</small>
            </label>
            <select
              onChange={handleChange}
              value={form?.stock}
              name="stock"
              className="w-100"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="form-fields mb-3">
            <label htmlFor="" className="text-dark">
              <small>Player Check Button</small>
            </label>
            <select
              onChange={handleChange}
              value={form?.playerCheckBtn}
              name="playerCheckBtn"
              className="w-100"
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <label htmlFor="" className="text-dark">
            <small>Sequence</small>
          </label>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="seq"
              onChange={handleChange}
              value={form?.seq}
              type="text"
              placeholder="Enter Seq"
            />
          </div>
          {form?.apiName === "smileOne" && (
            <div className="form-fields mb-3">
              <label htmlFor="" className="text-dark">
                <small>Allow User</small>
              </label>
              <select
                onChange={handleChange}
                value={form?.use}
                name="use"
                className="w-100"
              >
                <option value="">Select</option>
                <option value="a">a</option>
                <option value="b">b</option>
                <option value="c">c</option>
              </select>
            </div>
          )}
          <label htmlFor="" className="text-dark">
            <small>Add Packages</small>
          </label>
          {cost &&
            cost?.map((item, index) => (
              <div className="d-flex form-fields mb-3" key={index}>
                <input
                  className="w-100"
                  name={`id-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.id || ""}
                  type="text"
                  placeholder="Enter id"
                />
                <input
                  className="w-100"
                  name={`amount-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.amount || ""}
                  type="text"
                  placeholder="Enter Amount"
                />
                <input
                  className="w-100"
                  name={`pack-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.pack || ""}
                  type="text"
                  placeholder="Enter Pack in details"
                />
                <input
                  className="w-100"
                  name={`buyingprice-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.buyingprice || ""}
                  type="text"
                  placeholder="Enter Buying Price"
                />
                <input
                  className="w-100"
                  name={`price-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.price || ""}
                  type="text"
                  placeholder="Enter Price"
                />
                <input
                  className="w-100"
                  name={`fakePrice-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.fakePrice || ""}
                  type="text"
                  placeholder="Enter Fake Price"
                />
                <input
                  className="w-100"
                  name={`resPrice-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.resPrice || ""}
                  type="text"
                  placeholder="Enter Reseller Price"
                />
                <input
                  className="w-100"
                  name={`pimg-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.pimg || ""}
                  type="text"
                  placeholder="Enter Image Link"
                />
                <input
                  className="w-100"
                  name={`cat-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.cat || ""}
                  type="text"
                  placeholder="Enter Category"
                />
                <input
                  className="w-100"
                  name={`cimg-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.cimg || ""}
                  type="text"
                  placeholder="Enter cimg"
                />
                <button onClick={() => handleAddCostField(index)}>+</button>
                {index > 0 && (
                  <button onClick={() => handleRemoveCostField(index)}>
                    -
                  </button>
                )}
              </div>
            ))}
          <button className="w-100 py-3" onClick={handleUpdateProduct}>
            Update
          </button>
        </div>
      </div>
      {/* API PRO LIST  */}
      {form.apiName === "smileOne" && data && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>ID</th>
              <th>SPU</th>
              <th>PRICE</th>
              <th>COST PRICE</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr>
                  <td>{item?.id}</td>
                  <td>{item.spu}</td>
                  <td>{item.price}</td>
                  <td>{item.cost_price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {form?.apiName === "moogold" && moogold && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>PRICE</th>
            </tr>
          </thead>
          <tbody>
            {moogold &&
              moogold?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.variation_id}</td>
                    <td>{item?.variation_name}</td>
                    <td>{item.variation_price}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {form.apiName === "yokcash" && yokcash && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>Product Id</th>
              <th>Name</th>
              <th>Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {yokcash?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.id}</td>
                  <td>{item?.nama_layanan}</td>
                  <td>{item?.kategori}</td>
                  <td>{item?.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </AdminLayout>
  );
};

export default AdminEditProduct;
