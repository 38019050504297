import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import { message } from "antd";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import LockIcon from "@mui/icons-material/Lock";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import "./Register.css";

const Register = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    fname: "",
    email: "",
    mobile: "",
    password: "",
    cpassword: "",
  });
  const [tab, setTab] = useState(0);
  const [otp, setOtp] = useState(null);
  const [userEnteredOtp, setUserEnteredOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form?.password !== form?.cpassword) {
      return message.error("Password is not matching");
    }
    try {
      const res = await axios.post("/api/user/send-mobile-otp", form);
      if (res.data.success) {
        setTab(1);
        const {
          otp: encryptedOTP,
          key: encryptedKey,
          iv: encryptedIv,
        } = res.data.data;

        const key = CryptoJS.enc.Hex.parse(encryptedKey);
        const iv = CryptoJS.enc.Hex.parse(encryptedIv);

        // MOBILE OTP
        const decryptedOTP = CryptoJS.AES.decrypt(
          { ciphertext: CryptoJS.enc.Hex.parse(encryptedOTP) },
          key,
          { iv: iv }
        ).toString(CryptoJS.enc.Utf8);
        setOtp(decryptedOTP);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleRegister(e) {
    e.preventDefault();

    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(form?.email)) {
      message.error("Invalid email format");
      return;
    }
    if (form?.mobile?.length > 10 || form?.mobile?.length < 10) {
      return message.error("Enter 10 digits Mobile Number only");
    }

    try {
      const res = await axios.post("/api/user/register", form);
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/login");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Layout>
      <div className="container-fluid register-container hero-container">
        <div className="row text-center">
          <div className="d-block m-auto col-12 col-sm-12 col-md-6 col-lg-6">
            {tab === 0 && (
              <form className="register-form">
                <h4>Create Account</h4>
                <span>Enter valid registration information!</span>
                <div className="form-fields mb-3">
                  <label className="text-start d-block">Full name</label>
                  <input
                    onChange={handleChange}
                    value={form?.fname}
                    name="fname"
                    type="text"
                    className="w-100"
                    placeholder="Full name"
                  />
                  <PersonIcon className="icon" />
                </div>
                <div className="form-fields mb-3">
                  <label className="text-start d-block">Email address</label>
                  <input
                    onChange={handleChange}
                    value={form?.email}
                    name="email"
                    type="email"
                    className="w-100"
                    placeholder="Email address"
                  />
                  <EmailIcon className="icon" />
                </div>
                <div className="form-fields mb-3">
                  <label className="text-start d-block">Phone number</label>
                  <input
                    onChange={handleChange}
                    value={form?.mobile}
                    name="mobile"
                    type="text"
                    className="w-100"
                    placeholder="Phone number"
                  />
                  <MobileFriendlyIcon className="icon" />
                </div>
                <div className="form-fields mb-3">
                  <label className="text-start d-block">Password</label>
                  <input
                    onChange={handleChange}
                    value={form?.password}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="w-100"
                    placeholder="Password"
                  />
                  <RemoveRedEyeIcon
                    className="icon eyeicon"
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  />
                  <LockIcon className="icon" />
                </div>
                <div className="form-fields mb-3">
                  <label className="text-start d-block">Confirm password</label>
                  <input
                    onChange={handleChange}
                    value={form?.cpassword}
                    name="cpassword"
                    type={showPassword ? "text" : "password"}
                    className="w-100"
                    placeholder="Confirm password"
                  />
                  <RemoveRedEyeIcon
                    className="icon eyeicon"
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  />
                  <LockIcon className="icon" />
                </div>
                <div className="form-fields agreeterms">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckIndeterminate"
                    />
                    <label
                      class="form-check-label"
                      for="flexCheckIndeterminate"
                    >
                      I agree to the Terms and conditions and Privacy policy
                    </label>
                  </div>
                </div>
                <button className="register-btn" onClick={handleSubmit}>
                  Sign Up
                </button>
                <div className="forgot-pass d-flex justify-content-between">
                  <h6 className="text-center my-2">
                    Already a Customer?{" "}
                    <Link to="/login" style={{ color: "var(--t)" }}>
                      Sign In
                    </Link>
                  </h6>
                </div>
              </form>
            )}

            {tab === 1 && (
              <form className="register-form">
                <h1>Verification</h1>
                <div className="form-fields mb-3">
                  <label className="form-label text-start text-white d-block">
                    Enter Mobile OTP
                  </label>
                  <input
                    onChange={(e) => setUserEnteredOtp(e.target.value)}
                    value={userEnteredOtp}
                    type="text"
                    className="w-100"
                    placeholder="Enter 4 digits Mobile OTP"
                  />
                </div>
                <button className="register-btn" onClick={handleRegister}>
                  Verify And Register
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
