import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import "../Footer/Footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        {location?.pathname === "/" && (
          <Link
            className="whatsapp-icon-cont"
            to="https://t.me/topupzoneofficial"
            target="_blank"
          >
            <span>Support</span>
            <TelegramIcon className="icon" />
          </Link>
        )}
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <div className="footer-logo" onClick={() => navigate("/")}>
              <h6>
                Topup<span>Zone</span>
              </h6>
            </div>
            <p>
              Enjoy the experience of purchasing Vouchers and automatic Game Top
              Up whenever and wherever you want.
            </p>
            <span>topupzoneofficial@gmail.com</span>
            <br />
            <span>Made in India ❤️</span>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Quick Links</h6>
            <ul>
              {!user ? (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              ) : (
                <li>
                  <Link to="/user-dashboard">Dashboard</Link>
                </li>
              )}
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Important Links</h6>
            <ul>
              <li>
                <Link to="/terms-and-conditions">Terms and conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>Support</h6>
            <div className="social-media-links">
              <Link target="_blank" to="https://t.me/topupzoneofficial">
                <TelegramIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://instagram.com/topupzone.in">
                <InstagramIcon className="icon" />
              </Link>
              <Link target="_blank" to="mailto:topupzoneofficial@gmail.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
          </div>

          <hr />
          <span className="text-center">
            <small>
              All Rights Reserved © 2024 | TOPUP ZONE |{" "}
              <br className="d-block d-md-none d-lg-none" />
              Website designed & developed by{" "}
              <Link
                target="_blank"
                style={{ color: "var(--t)", fontWeight: "500" }}
                to="https://aashirdigital.com/"
              >
                ~@aashirdigital
              </Link>
            </small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
