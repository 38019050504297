import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import IMAGES from "../img/image";
import axios from "axios";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import getUserData from "../utils/userDataService";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./Reports.css";

const Reports = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [payments, setPayments] = useState(null);
  const [orders, setOrders] = useState(null);
  const [loading, setLoading] = useState(null);

  async function getUserPayments() {
    try {
      const res = await axios.post("/api/payment/get-user-payments", {
        email: user?.email,
      });
      if (res.data.success) {
        setPayments(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const getAllUserOrders = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/order/get-user-orders",
        { email: user?.email },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setOrders(res.data.data.reverse());
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (user !== null) {
      getUserPayments();
      getAllUserOrders();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="reports-container">
          <div className="reports-btn">
            <button
              onClick={() => setTab(0)}
              className={`${tab === 0 && "active"}`}
            >
              Payments
            </button>
            <button
              onClick={() => setTab(1)}
              className={`${tab === 1 && "active"}`}
            >
              Purchase
            </button>
            <button
              onClick={() => setTab(2)}
              className={`${tab === 2 && "active"}`}
            >
              Ledger
            </button>
          </div>
          {tab === 0 && (
            <div className="reports">
              <h5>Transaction History</h5>
              <table className="table table-warning table-bordered">
                <thead className="">
                  <tr>
                    <th>
                      <small>Txn Id</small>
                    </th>
                    <th>
                      <small>Order Details</small>
                    </th>
                    <th>
                      <small>Amount</small>
                    </th>
                    <th>
                      <small>Type</small>
                    </th>
                    <th>
                      <small>Date</small>
                    </th>
                    <th>
                      <small>Status</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {payments &&
                    payments?.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <small>{item?.orderId}</small>
                          </td>
                          <td>
                            <small>{item?.productInfo}</small>
                          </td>
                          <td>
                            <small>{item?.amount}</small>
                          </td>
                          <td>
                            <small>{item?.paymentType}</small>
                          </td>
                          <td>
                            <small>
                              {new Date(item?.createdAt).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                  second: "numeric",
                                  minute: "numeric",
                                  hour: "numeric",
                                }
                              )}
                            </small>
                          </td>
                          <td>
                            <small>{item?.status}</small>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
          {tab === 1 && (
            <div className="reports">
              <h5>Order History</h5>
              <table className="table table-warning table-bordered">
                <thead>
                  <tr>
                    <th>
                      <small>Order ID</small>
                    </th>
                    <th>
                      <small>Product</small>
                    </th>
                    <th>
                      <small>Date</small>
                    </th>
                    <th>
                      <small>Status</small>
                    </th>
                    <th>
                      <small>Total</small>
                    </th>
                    <th>
                      <small>Action</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small>{item?.orderId}</small>
                        </td>
                        <td>
                          <small>{item?.productinfo}</small>
                        </td>
                        <td>
                          <small>
                            {item?.createdAt
                              ? new Date(item?.createdAt).toLocaleString(
                                  "default",
                                  {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                  }
                                )
                              : ""}
                          </small>
                        </td>
                        <td>
                          <small
                            className={
                              item?.status === "pending"
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            {item?.status}
                          </small>
                        </td>
                        <td>
                          <small>{item?.amount}</small>
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              navigate(`/view-order/${item?.orderId}`)
                            }
                            className="view-btn"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {tab === 2 && <div className="reports"></div>}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Reports;
